export const generateImageUrl = (
  src: string,
  {
    width,
    height,
    fit = "cover",
    dpi = 3,
  }: {
    width: number;
    height: number;
    fit?: string;
    dpi?: number;
  }
) => {
  // Prefix src with https: if it starts with //
  if (src.startsWith("//")) {
    src = `https:${src}`;
  }
  return `https://gowindmill-images.com/cdn-cgi/image/fit=${fit},width=${width},height=${height},dpi=${dpi}/${src}`;
};
